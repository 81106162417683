.container {
  min-height: calc(100vh - 112px);
}

.progressContainer {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;

  span {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 104px);
  }

  .progressContainer {
    top: 56px;
  }
}

.container {
  padding: 16px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // background-color: #00000069;

  h1 {
    margin: 0;
    margin-bottom: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.334;
    letter-spacing: 0em;
    color: #222;
    font-size: 48px;
    font-weight: 800;
  }

  pre {
    margin-top: 22px;
    font-size: 20px;
  }
}

.qr_box {
  width: 350px;
  height: 350px;
  border: 1px solid #222;
  // border-radius: 9px;
  text-align: center;
}
