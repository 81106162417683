.container {
  min-height: calc(100vh - 112px);
}
.progressContainer {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;

  span {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 104px);
  }

  .progressContainer {
    top: 56px;
  }
}

.cell {
  cursor: pointer;
}

.cell:hover {
  background-color: #757575 !important;
  color: #fff !important;
}

.saleBtn {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.card_container {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
}

.card_header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sale10Btn:hover {
  color: #7431fa;
}

.sale20Btn:hover {
  color: #d32f2f;
}
