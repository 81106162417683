.container {
  min-height: calc(100vh - 112px);

  .box {
    max-width: 400px;
    margin: auto;
    margin-top: 16px;
    display: "flex";
    justify-content: "center";
    flex-direction: "column";
  }
}

.progressContainer {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;

  span {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 104px);
  }

  .progressContainer {
    top: 56px;
  }
}

.result_box {
  padding: 14px;

  a {
    display: flex;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: #757575;
    padding: 4px;

    &:hover {
      background-color: #757575;
      color: #fff;
    }
  }
}
