.container {
  min-height: calc(100vh - 178px);

  & * {
    font-family: "Cairo", sans-serif !important;
  }
}

.progressContainer {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;

  span {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 345px);
  }

  .progressContainer {
    top: 56px;
  }
}

// Responsive table
.datagrid_container {
  height: calc(100vh - 178px);

  @media (max-width: 600px) {
    height: calc(100vh - 345px);
  }
}
