.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a202c;

  p {
    color: #a0aec0;
    font-size: 18px;
    margin: 0;
    letter-spacing: .05em;
  }
}
