*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Cairo", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

th {
  font-weight: 600 !important;
}

.originalPrice {
  text-decoration: line-through;
  color: #757575;
}

/*  */
.visited {
  background-color: #299bd8;
  color: #fff;
}

.visited:hover {
  color: #299bd8;
}
