.container {
  min-height: calc(100vh - 112px);

  h1 {
    margin-bottom: 16px;
  }

  .posts_box {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    a {
      border: 1px solid #000;
      padding: 8px;
      text-decoration: none;
      color: #000;
    }
  }
}
.progressContainer {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;

  span {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 104px);
  }

  .progressContainer {
    top: 56px;
  }
}

.redRow {
  background-color: #f1f1f1;
}
